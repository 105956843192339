@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
$body-font-family: "Red Hat Text", sans-serif;
$heading-font-family: "Roboto", sans-serif;

$sidebar-max-width: 255px;

$sidebar-grab-width: 19px;
$sidebar-md-max-width: $sidebar-max-width + $sidebar-grab-width;
$sidebar-background: white;
$sidebar-folded-max-width: 82px;
$sidebar-folded-md-max-width: $sidebar-folded-max-width + $sidebar-grab-width;
$sidebar-iconsize: 16px;

$topbar-menu-height: 48px;
$topbar-mobile-menu-height: 64px;
$topnav-background: white;

$mobile-menu-height: 48px;

// colours

// core
$color-light-grey: #eef2f3;
$color-light-grey2: #cfd7d7;
$color-dark-grey: #3b4456;
$color-ruby: #ef426f;

// secondary
$color-yellow: #ffd952;
$color-purple: #8060aa;
$color-orange: #ff9800;
$color-blue: #00c1d4;
$color-green: #12b77c;
$color-red: #dd304f;
$color-beige: #d1b784;
$color-grey: #798889;

$color-table-highlight: #f7f8f7;

// util
$color-white: #ffffff;
$color-black: #000000;

//@include background-opacity($color-green, 0.4);

$desktop-xxs-max: 480;
$desktop-xs-max: 600;
$desktop-sm-max: 840;
$desktop-md-max: 960;
$desktop-lg-max: 1280;
$desktop-xl-max: 1440;
$desktop-xxl-max: 1600;

$phone-port-max: 360;
$phone-land-max: 600;
$tablet-port-max: 720;
$tablet-max-width: 1024px;
$desktop-min-width: 1025px;

