$marginEditText: 8px;
$heightOneline: 52px;

body.light {
    .mat-table {
        font-family: $body-font-family;
        border-spacing: 0;
        border-collapse: separate;
        overflow: hidden;

        .mat-header-row,
        .mat-row,
        .mat-footer-row {
            @include media-breakpoint("xs") {
                border-bottom-color: $color-light-grey2;
            }
        }
    }
    .mat-table-wrapper {
        font-size: 1rem;
        
        .summary-row {
            height: $heightOneline;
            display: flex;
            border-width: 0;
            border-bottom-width: 1px;
            border-style: solid;
            align-items: center;
            box-sizing: border-box;
            border-bottom-color: rgba(0, 0, 0, 0.12);
        }

        .summary-cell {
            // flex: 1;
            display: flex;
            align-items: center;
            overflow: hidden;
            word-wrap: break-word;
            min-height: inherit;
            font-size: 0.875rem;
        }

        .summary-cell-title {
            // padding-right: 0.625rem;
            justify-content: flex-end;
        }

        // style for select column
        .tb-select-cell,
        .tb-select-header {
            width: 4.5rem;
            flex: none;
        }

        .tb-action-cell {
            flex: none;
            width: 3rem;
            position: relative;
            overflow: visible;
        }

        .mat-header-cell,
        .mat-cell {
            &.hasWidth {
                flex: none;
            }
        }

        .mat-header-cell {
            &.header-alignment-right {
                justify-content: flex-end;
                text-align: right;

                @include media-breakpoint("xs") {
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }

        .mat-row {
            min-height: $heightOneline;

            &.expansion-detail-row {
              min-height: initial;
            }
        }

        .ellipsis {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;

            @include media-breakpoint("xs") {
                white-space: initial;
                text-overflow: initial;
            }
        }

        .mat-table-cell-content-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;

            .tb-cell-value {
                flex: 1;
                width: 100%;
                max-width: 100%;

                &.edit {
                    height: 2.188rem;
                    display: inline-block;
                    line-height: initial;
                    position: relative;
                    margin-left: 0.625rem;

                    &::before {
                        content: "";
                        background-color: white;
                        border-bottom: solid 2px $color-ruby;
                        display: inline-block;
                        width: 100%;
                        /* Firefox */
                        height: -moz-calc(100% - #{2 * $marginEditText});
                        /* WebKit */
                        height: -webkit-calc(100% - #{2 * $marginEditText});
                        /* Opera */
                        height: -o-calc(100% - #{2 * $marginEditText});
                        /* Standard */
                        height: calc(100% - #{2 * $marginEditText});
                        position: absolute;
                        top: $marginEditText;
                    }

                    input {
                        font-size: 0.875rem;
                        color: $color-dark-grey;
                        display: inline-block;
                        height: 100%;
                        position: relative;
                    }

                    .edit-dollar-sign {
                        position: absolute;
                        top: 25%;
                        left: 4px;
                        margin-top: 2px;
                    }

                }

                &.edit-aligment-right {
                    input {
                        padding-right: 0.625rem;
                        padding-left: 1rem;
                        text-align: right;
                    }
                }
            }

            .tb-cell-link {
                color: $color-ruby;
                border-bottom: 2px solid $color-ruby;

                &:hover,
                &:focus,
                &:active {
                    color: $color-red;
                    border-color: transparent;
                }
            }

            &.one-line {
                .tb-cell-value {
                    height: $heightOneline;
                    line-height: $heightOneline;
                }
            }

            &.theme-grey {
                color: $color-dark-grey;
            }

            &.theme-ruby {
                color: $color-ruby;
            }

            &.underline {
                .tb-cell-value {
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }

        .cell-alignment-right {
            text-align: right;
            padding-left: 1.4rem;  

            @include media-breakpoint("xs") {
                text-align: left;
                padding-left: 0;
            }       
        }
    }

    @include media-breakpoint("xs") {
        .mat-table-wrapper {
            .mat-table-cell-content-wrapper {
                align-items: normal;

                .mobile-label {
                    width: 10rem;
                    display: inline-block;
                    flex: 2;
                    position: relative;
                    top: -1px;
                    margin-right: 10px;
                }

                .tb-cell-value {
                    flex: 3;
                }

                &.one-line {
                    .tb-cell-value {
                        height: auto;
                        line-height: initial;

                        &.edit {
                            height: $heightOneline;
                            line-height: $heightOneline;
                        }
                    }
                }
            }
        }
    }

    // -------------------------------------------------------------------------------
    // for table
    // -------------------------------------------------------------------------------
    .table-column-container {
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        background: white;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid $color-light-grey2;
        flex: 1 1 auto;
        margin: 0.625rem 5px 1rem 5px;
        border-radius: 6px;

        &.noborder {
            margin: 0;
            box-shadow: none;
            border: none !important;
        }

        .table-status {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.75rem;

            &.has-paginator {
                position: relative;
                margin-top: -3.75rem;
            }
        }
    }

    .mat-card,
    .mat-tab-body {
        .table-column-container {
            margin: 0;
            box-shadow: none;
            border: 1px solid $color-light-grey2;
            overflow: hidden;

            .mat-header-row {
                border-bottom-color: $color-light-grey2;
            }

            .mat-row {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .super-finder {
        padding: 0 !important;

        .inner {
            padding: 0.625rem 1rem;
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
        }

        .tb-pagination {
            border-top: 1px solid $color-light-grey2;
            padding: 5px 16px;
        }

        &.noBackground {
            .mat-table {
                background: none;

                .mat-header-row {
                    background: none;
                }

                .mat-row {
                    background: none;
                }
            }
        }

        .table-no-data-row {
            position: absolute;
            height: 3.75rem;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 0.625rem;
        }
    }

    .mat-table {
        width: 100%;

        .mat-header-row {
            position: relative;
            background: $color-light-grey;

            .mat-header-cell {
                border-top: 0px solid $color-light-grey2;
                color: #3B4456;
                font-size: 0.875rem;
                line-height: 1.1;
                font-weight: 500;
            }
        }

        .mat-row {
            position: relative;

            &.hovered {
                background: $color-table-highlight;

                .tb-action-cell-content {
                    display: flex;
                }
            }

            &.highlighted {
                @include background-opacity($color-ruby, 0.15);

                .tb-action-cell-content {
                    background: none;
                }
            }

            &.abnormalResult {
                @include background-opacity($color-red, 0.15);     

                .tb-action-cell-content {
                    background: none;
                }
            }
        }
        
        &.mat-table-has-fields {
            @include media-breakpoint("xs") {
                border-spacing: 0;
                border-collapse: separate;
                border: 1px solid $color-light-grey2;
                border-radius: 6px;
                overflow: hidden;
            }
            
            .mat-header-row,
            .mat-row,
            .mat-footer-row {
                padding-right: 25px;
        
                @include media-breakpoint("xs") {
                    padding-right: 17px;
                }
            }

            mat-cell:first-of-type,
            mat-header-cell:first-of-type,
            mat-footer-cell:first-of-type {
                padding-left: 0.75rem;
        
                @include media-breakpoint("xs") {
                    padding-left: 0;
                }
            }

            mat-cell:last-of-type,
            mat-header-cell:last-of-type,
            mat-footer-cell:last-of-type {
                padding-right: 0.75rem;
            }

            .mat-form-field-type-mat-input,
            .mat-form-field-type-mat-select {
                width: 100%;
            }

            .cell {
                &-delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                    flex: none;
                    padding-right: 0 !important;

                    @include media-breakpoint("xs") {
                        position: relative;
                        margin-bottom: 0;
                    }

                    .button-text-mobile {
                        display: none;
                        color: $color-ruby;

                        @include media-breakpoint("xs") {
                            display: block;
                        }
                    }
                    
                    .mat-table-cell-content-wrapper {
                        justify-content: flex-end;

                        @include media-breakpoint("xs") {
                            justify-content: flex-start;
                        }
                    }

                    .mat-icon-button {
                        line-height: 36px;
                        width: 36px;
                        height: 36px;
                        min-height: 36px;
                        min-width: 36px;

                        @include media-breakpoint("xs") {
                            display: inline-flex !important;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            padding: 0 20px;
                            width: auto;
                            min-width: 64px;
                            height: 40px;
                            min-height: 40px;
                            max-height: 40px;
                            line-height: 40px;
                            background-color: #fceef1;
                            
                            .mat-button-focus-overlay {
                                opacity: 0.1 !important;
                                background-color: $color-ruby !important;
                            }
                        }

                        .mat-icon {
                            line-height: 20px;
                            width: 20px;
                            height: 20px;
                            min-height: 20px;
                            min-width: 20px;

                            @include media-breakpoint("xs") {
                                display: none !important;
                            }
                        }
                    }
                }
            }

            .mat-cell {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .mat-checkbox {
                margin-left: 12px;

                @include media-breakpoint("xs") {
                    margin-left: 0;
                }
            }

            .mat-form-field-type-mat-select {
                background: #fff;
                border-radius: 4px;
    
                .mat-form-field-infix {
                    width: 100% !important;
                }
            }
        }

        &.mat-table-plain {
            border: 1px solid $color-light-grey2;
            border-radius: 4px;
            background: transparent;

            .mat-header-row,
            .mat-row {
                background: transparent;
                border-color: $color-light-grey2;
                min-height: 42px;
            }

            .mat-row {
                &:last-child {
                    border-bottom: none;
                }
            }

            .mat-header-cell,
            .mat-cell {
                padding-left: 0.75rem !important;
                padding-right: 0.75rem !important;
                border-right: 1px solid $color-light-grey2;
        
                @include media-breakpoint("xs") {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    border-right: none;
                }

                &:last-of-type {
                    border-right: none;
                }
            }
        }

        // style for select column
        .tb-select-cell,
        .tb-select-header {
            width: 4.5rem;
            flex: none;
        }

        .tb-action-text {
            color: $color-ruby;
        }

        .tb-action-arrow {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            margin: 0 4px;
            position: relative;
            top: 12px;
            margin-left: 0.5rem;
        }

        .tb-action-cell-content-action-list-mobile {
            color: $color-ruby;
        }
        
        .tb-action-header {
            flex: none;
            width: 3rem;
            padding-left: 0.625rem;
            cursor: pointer;

            &--hidden {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                width: 0;
            }
        }

        // style for action cell
        .tb-action-cell {
            &--hidden {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                width: 0;
            }
        
            @include media-breakpoint("xs") {
                position: relative;
            }

            .tb-action-cell-content {
                position: absolute;
                color: $color-ruby;
                right: 0px;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 5.625rem;
                padding-right: 0.625rem;
                background: linear-gradient(270deg,
                        $color-table-highlight 80%,
                        rgba($color-table-highlight, 0) 100%);
        
                @include media-breakpoint("xs") {
                    position: relative;
                    padding: 0;
                    width: 100%;
                    justify-content: flex-end;
                }
        
                .mat-button {
                    border-radius: 4px !important;
                }

                .tb-action-cell-content-title {
                    margin-right: 1.875rem;
                    padding: 0.625rem;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 5.625rem;
                    }

                    &:last-child {
                        margin-right: 0.625rem;
                    }
                }

                display: none;
            }
        }

        // for warning style
        .tb-warning-header {
            flex: none;
            width: 2.875rem;
            padding-left: 0;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .tb-warning-icon {
                width: 1rem;
                height: 1rem;
                font-size: 1rem;
                color: $color-dark-grey;
            }
        }

        .tb-warning-cell {
            flex: none;
            width: 2.875rem;
            padding-left: 0;
            align-items: center;
            justify-content: center;

            .tb-warning-icon {
                width: 1.25rem;
                height: 1.063rem;
                font-size: 1.063rem;
                cursor: pointer;
            }
        }

        .tb-detail-item-cell,
        .tb-detail-item-header {
          width: 2rem;
          flex: none;
          padding-left: 0;
          align-items: center;
          justify-content: center;
        }

        .tb-detail-item-cell {
          .tb-detail-item-icon {
            cursor: pointer;
          }
        }
    }

    .mobile {
        .mat-table {
            .tb-action-cell-content {
                display: flex;
                background: none;
            }
        }
    }

    .mat-paginator-container {
        min-height: 1.875rem !important;
        max-height: 3.125rem !important;
        flex-wrap: inherit !important;
        padding: 0 8px;

        .mat-paginator-page-size {
            flex: 1 1 auto;
            // margin-top: 5px;
        }
    }

    .mobile-label {
        display: none;
        font-weight: 500;
        color: $color-dark-grey;
    }

    .mat-paginator-range-label {
        margin: 0 0.625rem !important;
    }

    .mat-paginator-range-actions {
        // align-items: baseline !important;
    }


    @include media-breakpoint("xs") {
        .mat-paginator-page-size-select {
            // margin: 6px 4px 0 4px;
            // width: 56px;
        }

        .mat-table {
            .mat-header-row {
                display: none;
            }

            .mat-row {
                &:last-child {
                    border-bottom: none;
                }
            }

            .tb-action-cell {
                margin: 0px;
                display: block;
                width: auto;
                padding: 0;

                .tb-action-cell-content {
                    height: auto;
                    background: none;
                    display: flex;
                }
            }

            .tb-action-cell-content-action-list-mobile {
                top: -0.5rem;
                right: -0.625rem;
            }

            .mat-row {
                flex-direction: column;
                align-items: start;
                padding: 22px 17px 15px 17px;
                display: block;

                &:after {
                    display: none;
                }
            }
        }

        .mat-cell {
            //max-height: 18px;
            min-height: auto;
            margin-bottom: 1rem;
            padding-right: 0 !important;

            &:first-of-type {
                padding-left: 0;
            }
        }

        .tb-pagination {
            padding: 0.625rem 0px 1rem 0px !important;
            flex-direction: column !important;
            align-items: flex-end;

            .mat-paginator-container {
                max-height: initial !important;
                min-height: initial !important;
                flex-direction: column-reverse;
                align-items: flex-end;

                .mat-paginator-container {
                    padding: 0;
                }

                .mat-form-field-wrapper {
                    padding-bottom: 0;

                    .mat-form-field-underline {
                        display: none;
                    }

                    .mat-form-field-infix {
                        padding: 0;
                        border: 0;
                    }
                }
            }
        }
    }


    // -----------------------------------------------------------------------------
    // Table filter
    // -----------------------------------------------------------------------------
    .column-filter {
        &-container {
            position: relative;
            padding: 1.5rem 1.25rem;
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            @include media-breakpoint("lt-md") {
                flex-wrap: wrap;
            }

            @include media-breakpoint("xs") {
                padding: 10px 17px;
            }

            > .mat-icon-button {
                right: -8px;

                @include media-breakpoint("lt-md") {
                    order: 2;
                }
            }
        }

        &-title {
            display: block;
            font-family: $body-font-family;
            padding-top: 5px;
        }
    }

    .filter-chip-list {
        width: 100%;
        padding-top: 4px;

        @include media-breakpoint("lt-md") {
            order: 3;
            flex-basis: 100%;
            max-width: 100%;
        }

        .chip-content-wraper {
            max-width: 25rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            // for dot dot dot text
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.mat-tooltip {
                max-width: none;
            }
        }
    }
    
    .column-filter-container--healthlink {
        .mat-chip-list-wrapper {
            padding: 0;

            @include media-breakpoint("lt-md") {
                margin-right: -40px;
            }
        }
    }

    .mat-chip-list-wrapper {
        padding: 0 8.25rem 0 1rem;

        @include media-breakpoint("lt-md") {
            padding: 0;
            margin: 0 -8px;
        }

        .mat-chip {
            &.ghost-chip {
                position: absolute;
                z-index: 5;
                right: 56px;
                top: 24px;
                padding: 7px !important;
                background: $color-white !important;
                border: 1px dashed $color-dark-grey !important;
                font-weight: 400;
                font-size: 0.875rem;

                @include media-breakpoint("xs") {
                    right: 52px;
                    top: 11px;
                }

                .mat-icon {
                    margin-right: 4px !important;
                    color: #3B4456 !important;
                }
            }
            
            &.filter-chip {
                background: $color-dark-grey !important;
                color: $color-white !important;
                font-weight: 400;
                font-size: 0.875rem;

                @include media-breakpoint("lt-lg") {
                    max-width: 415px;
                }

                @include media-breakpoint("lt-md") {
                    max-width: 97.5%;
                }

                .mat-icon {
                    color: $color-white !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}