.breadcrumbs {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 15;

    &-container {
        background-color: $color-dark-grey;
        height: 4.375rem;

        .mat-divider {
            &.mat-divider-vertical {
                border-color: #798889;
            }
        }

        .title {
            font-size: 1.25rem;
            color: #9DA2AB;

            &.last {
                color: white;
            }
        }

        .page-title{
            font-size: 1.25rem;
            color: white;
        }

        .sub-title {
            color: #9DA2AB;

            &--highlight {
                position: relative;
                bottom: -1px;
                color: white;
            }
        }
    }
}