@import "./src/styles/_breakpoints.scss";

$toolbarHeight: 68px;

// column filter dialog
.column-filter-dialog {
    font-size: 1rem;
    overflow: hidden;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);

    .column-filter-wrapper {
        background: #fff;
        width: 16.563rem;

        .mat-toolbar-multiple-rows {
            min-height: 50px;
        }

        .mat-toolbar {
            height: 3.125rem;

            .mat-toolbar-row {
                padding: 0 15px;
                height: 3.125rem !important;
                background-color: $color-dark-grey;

                .mat-button {
                    padding: 0;
                    max-width: 1.5rem;
                    min-width: 1.5rem;
                }
            }
        }

        .content {
            padding: 1.5rem;

            .mat-form-field {
                width: 100%;
            }

            .mat-form-field-wrapper {
                padding-bottom: 5px;
            }
        }

        .actions {
            padding: 0 15px 5px 15px;
            flex-direction: row;
            justify-content: flex-end;
            display: flex;

            .mat-button-wrapper {
                color: $color-ruby;
            }
        }
    }
}

// general filter dialog
.general-filter-dialog-panel {
    @include media-breakpoint("gt-xs") {
        padding-left: 0;
    }

    .content-container {
        padding: 1rem 0;
    }

    .general-filter-dialog-component {
        .element {
            > .mat-form-field {
                width: 100%;
            }
        }
    }
}

// mail dialog
.mail-compose-attachment-dialog {
    .mat-dialog-container {
        @include media-breakpoint("gt-sm") {
            min-width: 44.375rem;
        }

        table {
            width: 100%;
        }
    }

    .save-button {
        margin-left: 15px;
    }
}


.mail-dialog-content-wrapper {
    width: 70vw;

    @include media-breakpoint("lt-sm") {
        width: 100vw;
        max-height: 100vh;
        max-height: stretch;
        height: 100vh;
    }

    .dialog-content-header {
        width: 100%;
    }

    .mat-dialog-content {
        width: 100%;
        height: 60vh;

        @include media-breakpoint("lt-sm") {
            height: 100%;
            max-height: 100%;
            max-height: -moz-calc(100% - 7.25rem);
            /* WebKit */
            max-height: -webkit-calc(100% - 7.25rem);
            /* Opera */
            max-height: -o-calc(100% - 7.25rem);
            /* Standard */
            max-height: calc(100% - 7.25rem);
        }

        .mat-tab-group {
            height: 100%;
            .mat-tab-body-wrapper {
                flex: 1;

                .mat-tab-body-content {
                    padding: 0;
                }
            }
        }
    }

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        padding: 0 7px 0 27px;
    }

    .close-button {
        color: $color-ruby;

        span {
            margin-left: 5px;
            font-weight: 500;
        }
    }
}

.attachment-file-menu {
    .file-input {
        width: 100%;
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0;
        padding: 0;
        opacity: 0;
    }
}

// Add new client dialog
.add-new-client-dialog-panel {
    @include media-breakpoint("xs") {
        margin-left: auto !important;
    }

    .add-new-client-dialog {
        width: 20rem;
        display: inline-block;
    }
}

// --------------------------------- match referrer detail dialog ------------------------
// ---------------------------------------------------------------------------------------
.match-message-entity-dialog-panel {
    font-size: 1rem;
    .btn {
        cursor: pointer;
    }

    .match-message-entity-dialog {
        font-size: 1rem;
        display: block;
        position: relative;

        .mat-dialog-content {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            display: inline-block;
            width: 33.313rem;
            height: 30rem;
            margin: 0;
            padding: 0.625rem 1.375rem;

            @include media-breakpoint("xs") {
                height: 100%;
                max-height: -moz-calc(100vh - 7.875rem);
                /* WebKit */
                max-height: -webkit-calc(100vh - 7.875rem);
                /* Opera */
                max-height: -o-calc(100vh - 7.875rem);
                /* Standard */
                max-height: calc(100vh - 7.875rem);
                // max-height: 100vh;
                width: 100vw;
            }
        }

        .content-container {
            display: flex;
            color: $color-dark-grey;
            background-color: white;
            min-height: 100%;

            .referrer-detail {
                margin: 0;

                p {
                    margin: 0;
                }

                .header {
                    font-weight: 500;
                    margin: 0;
                }

                .mat-card {
                    margin: 0;
                    min-height: 100%;

                    &.matching {
                        background: #DAF9ED;
                        border: 2px solid #12B77C;
                    }

                    &.create-new {
                        border: 2px dashed #CFD7D7;
                        border-radius: 6px;
                        height: 25.5rem;
                        padding: 0;

                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        box-shadow: none;

                        .referrer-list {
                            margin-top: 1.25rem;
                            width: 95%;
                        }

                        .btn {
                            width: 95%;

                            &.matching-existing {
                                margin-top: 1rem;
                            }
                        }

                        .or-text {
                            font-weight: 500;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }

                        .matching-list {
                            margin-top: 0.625rem;
                            width: 95%;
                            display: block;
                            height: 2.75rem;
                            position: relative;

                            .search-icon {
                                position: absolute;
                                right: 0.875rem;
                                top: 0.875rem;
                                z-index: 2;
                                color: $color-ruby;
                            }
                        }

                    }

                    .close-btn {
                        font-size: 1rem;
                        position: absolute;
                        right: 0.625rem;
                        top: 0.625rem;
                    }
                }

                .referrer-brief-info {
                    margin-bottom: 1.25rem;
                    font-weight: 500;
                }

                .referrer-info {
                    margin-top: 1.25rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                &.second {
                    .create-new-btn {
                        background: #EF426F;
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
                        border-radius: 6px;
                        height: 2.75rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        padding-left: 1rem;
                        padding-right: 1rem;

                        .icon-element {
                            margin-right: 0.625rem;
                        }
                    }
                }

                &.unmatch {
                    .mat-card {
                        background: #FFE8EE;
                        border: 2px solid #DD304F;
                    }
                }
            }
        }

        .toolbar {
            font-weight: 500;
            height: 3.125rem;
            display: flex;
            justify-content: space-between;
            background: $color-dark-grey;
            color: white;

            .close-btn {
                color: white;
            }
        }

        .mat-dialog-actions {
            color: $color-ruby;
            margin-bottom: 0;

            .btn {
                font-size: 1rem;
            }
        }
    }
}


// -------------------- For attachment Text ---------------------------------
// --------------------------------------------------------------------------
// attachment element
.attachment-element {
    display: flex;
    align-items: center;
}

.attachments {
    .attachment-element {
        margin-bottom: 1rem;
        color: $color-ruby;

        .attachment-element-title {
            font-size: 0.875rem;
            font-weight: 500;
            margin-left: 12px;
            text-decoration: underline;
            cursor: pointer;
        }

        .attach-file-btn {
            font-size: 1rem;
            width: 15px;
            height: 17px;
            min-width: initial;
            min-height: initial;
        }

        .remove-btn {
            transform: scale(0.8);
            margin-left: 0.625rem;
            cursor: pointer;
        }
    }
}

// ---------------------- healthlink message info -------------------------
// ------------------------------------------------------------------------
.add-healthlink-status-info-dialog-panel {
    .mat-dialog-container {
        width: 342px;
    }

    .header {
        margin-bottom: 0.625rem;
    }

    .title {
        font-weight: 500;
    }
}

//-----------------Email-----------------------------

// for email overlay
.mail-overlay {
    bottom: 0;
    right: 0;
    height: 95%;
    visibility: hidden;
  
    app-email-compose-container {
      width: 100%;
      visibility: hidden;
    }
  
    @include media-breakpoint("gt-xs") {
      height: 90%;
    }
  
    .mail-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-right: 10px;
      padding-left: 10px;
      height: 100%;
      visibility: hidden;
  
      @include media-breakpoint("gt-xs") {
        padding-left: 0px;
      }
  
      app-email-compose {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-right: 2px;
          visibility: initial;
  
          &.minimized {
              height: auto;
          }
      }
  
      .mail-content-wrapper {
        width: 100%;
        height: 100%;
        //height: 792px;
        display: block;
        position: relative;
      
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 0;
        overflow: hidden;
      
        box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
        background: white;
        color: rgba(0, 0, 0, 0.87);
      
        font: 400 14px/20px "Red Hat Text", sans-serif;
        letter-spacing: normal;
  
        @include media-breakpoint("gt-xs") {
            width: 710px;
        }
      
        &.minimise {
          width: 300px;
          height: 56px;
  
          @include media-breakpoint("gt-xs") {
              height: 64px;
          }
        }
      
        .mat-toolbar-row,
        .mat-toolbar-single-row {
          padding: 0 20px 0 27px;
        }
      
        .close-button {
          span {
            margin-left: 5px;
            font-weight: 500;
          }
        }
      }
    }
}


