/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// customization for all the angular material element
// mat-form-field

html {
	height: stretch;
}

body.light {
	font-family: $body-font-family;
	font-size: 16px;
	min-height: 100vh;
	min-height: stretch;
	height: 100vh;

	.mat-form-field,
	.mat-select,
	.mat-option,
	.mat-optgroup-label,
	.mat-checkbox,
	.mat-calendar,
	.mat-button,
	.mat-raised-button,
	.mat-icon-button,
	.mat-stroked-button,
	.mat-flat-button,
	.mat-fab,
	.mat-mini-fab,
	.mat-dialog-title,
	.mat-tab-group,
	.mat-dialog-content {
		font-family: $body-font-family;
	}

	.mat-optgroup-label,
	.mat-dialog-title {
		font-weight: 500;
	}

	fuse-vertical-navigation {
		height: 100%;
		min-height: auto;
		max-height: auto;
	}
}

body.light {
	.mat-form-field {
		&.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
			color: $color-light-grey2 !important;
		}

		&.mat-form-field-readonly {
			.mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
				color: transparent !important;
			}
		}

		&.mat-focused {
			.mat-form-field-label {
				color: $color-dark-grey;
			}
		}

		&.mat-form-field-should-float {
			.mat-form-field-label {
				color: $color-dark-grey;
			}
		}
	}
}

.mat-form-field {
	&.mat-form-field-appearance-outline {
		&.mat-form-field-invalid {
			.mat-form-field-outline-thick {
				.mat-form-field-outline-start {
					color: $color-red;
				}

				.mat-form-field-outline-gap {
					color: $color-red;
				}

				.mat-form-field-outline-end {
					color: $color-red;
				}
			}

			.mat-form-field-label-wrapper {
				.mat-form-field-label {
					mat-label {
						color: $color-dark-grey;
					}
				}
			}

			.mat-form-field-infix {
				.mat-form-field-label-wrapper {
					.mat-form-field-label {
						mat-label {
							color: $color-red;
						}
					}
				}
			}
		}

		.mat-form-field-wrapper {
			margin: 0;
		}
	}

	.mat-form-field-wrapper {
		padding: 0;
	}
}

.mat-form-field {
	.mat-form-field-infix {
		border-color: #00000000;

		// customization of height for form-field
		padding-top: 8px;
		padding-bottom: 8px;
		
		.mat-form-field-type-mat-select {
			.mat-form-field-wrapper {
				background: #fff;
			}
		}

		.mat-form-field-label-wrapper {
			.mat-form-field-label {
				margin-top: -8px;
			}
		}

		.mat-select-arrow-wrapper {
			// fuse causes weird layout when using with kendo
			.mat-select-arrow {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid;
			}
		}
	}

	&.mat-form-field-can-float.mat-form-field-should-float {
		.mat-form-field-infix {
			.mat-form-field-label {
				margin-top: -6px;
			}
		}
	}
}

.mat-form-field {
	&.no-border {
		.mat-form-field-flex {
			padding: 0;
		}
		.mat-form-field-infix {
			border: 0;
		}
		.mat-form-field-wrapper {
			padding: 0;
		}
		.mat-form-field-underline {
			display: none;
		}
	}
}

// mat-error
.mat-error {
	color: $color-red;
}

// mat button
.mat-stroked-button,
.mat-raised-button {
	border-radius: 4px !important;
}

// for dropdown
body.light {
	.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
		color: $color-dark-grey;
	}

	.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
		color: $color-dark-grey;
	}
}

// for mat menu
.mat-menu-panel {
	.mat-menu-label {
		margin: 10px 10px 10px 15px;
		font-size: 16px;
		position: relative;
		.arrow_up {
			position: absolute;
			right: 0px;
			top: 5px;
		}
	}

	.mat-menu-content {
		.mat-menu-item {
			display: block;
			font-family: $body-font-family;
			line-height: inherit;
			height: auto;
			padding: 10px 16px;

			&:hover:not([disabled]) {
				background: $color-light-grey !important;
			}
		}
		// no need of bg color for mat menu with multiple checkboxes
		.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
			background: $color-white;
		}
	}
}

// for calendar mat-menu setting
.mat-menu-panel.calendar-setting {
	max-width: none;
}

// for mat-card
.mat-card {
	font-family: $body-font-family;
}

// for mat-chip -mat-chip-selected is overrided in pink color by Fuse
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
	background-color: #e2e8f0;
	color: #1e293b;
}

body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
	color: #1e293b;
	opacity: 0.4;
}
// Fix style problems which is overrided by Fuse
body.light {
	.mat-tab-group {
		.mat-tab-header {
			border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
		}

		.mat-tab-label-container .mat-tab-list {
			.mat-tab-labels {
				.mat-tab-label {
					padding: 0 1.25rem !important;
					
					&.mat-tab-label-active {
						background-color: white !important;
					}
				}
			}

			.mat-ink-bar {
				display: inline-block !important;
			}
		}

		.mat-tab-label,
		.mat-tab-link {
			font-family: $body-font-family;
		}
	}
}
