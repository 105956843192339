@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.3) {
    color: $color; /* The Fallback */
    color: rgba($color, $opacity);
}

@mixin border-color-opacity($color, $opacity: 0.3) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}
