@import "~@progress/kendo-theme-default/scss/scheduler/_index.scss";

// .k-scheduler div, .k-scheduler > * {
//   border-width: 0;
// }
// SCHEDULER

.k-scheduler {
  background: grey;
  border: none;
}

.k-scheduler-toolbar {
  background: white;
  height: 50px;
  padding: 0 !important;
  @include media-breakpoint("gt-xs") {
    min-height: 75px;
    height: auto;
    padding: 8px 8px !important;
  }

  .k-scheduler-navigation {
    //width: 100%;
    //min-width: 150px;

    li {
      a {
        cursor: pointer;

        &:active {
          text-decoration: none;
        }
        &:hover {
          text-decoration: none;
        }
      }
      margin: 0 3px;
      border-color: #0000001f;
      color: #ef426f;
      border-radius: 6px;
      border-width: 1px;
      background: #0000;
      padding: 7px 7px;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
    .k-nav-current {
      order: 1;
      color: #3b4456;
      padding-left: 0;
      a {
        font-weight: bold;
      }
      .k-i-calendar {
        display: none;
      }
    }
    .k-nav-prev {
      order: 2;
      @include media-breakpoint("xs") {
        display: none;
      }
      a {
        background: url(/assets/icons/gpm_prev_ruby.svg) no-repeat center center;
        span {
          &::before {
            content: none;
          }
        }
      }
    }
    .k-nav-next {
      order: 3;
      @include media-breakpoint("xs") {
        display: none;
      }
      a {
        background: url(/assets/icons/gpm_next_ruby.svg) no-repeat center center;
        span {
          &::before {
            content: none;
          }
        }
      }
    }
    .k-nav-today {
      order: 4;
      font-weight: 500;
      padding: 7px 20px;

      @include media-breakpoint("xs") {
        border: none;
        margin-top: 2px;
        border-radius: 50%;
        padding: 0;
        a {
          width: 24px;
          background: url(/assets/icons/gpm_today.svg) no-repeat center center;
          text-indent: -9999px;
        }
      }
    }
  }
}

// CHARTS

.k-var--chart-font {
  font-size: 14px;
}

.k-var--chart-title-font {
  font-size: 1.143em;
}

.k-var--chart-label-font {
  font-size: 0.857em;
}

.k-chart,
.k-sparkline,
.k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  border-width: 0;
}

.k-chart,
.k-stockchart {
  font-size: 14px;
  font-family: inherit;
  display: block;
  height: 400px;
}

.k-chart-surface {
  height: 100%;
}

.k-chart .k-popup {
  border-width: 0;
}

.k-chart-tooltip-wrapper .k-animation-container-shown {
  transition: left 80ms ease-in, top 80ms ease-in;
}

.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}

.k-chart-tooltip {
  font-size: 13.006px;
  line-height: 1.4285714286;
  padding: 4px 8px;
}

.k-chart-tooltip th {
  width: auto;
  text-align: center;
  padding: 1px;
}

.k-chart-tooltip td {
  width: auto;
  text-align: left;
  padding: 2px 4px;
  line-height: 1.4285714286;
  vertical-align: middle;
}

.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip {
  border-width: 1px;
  border-style: solid;
}

.k-chart-shared-tooltip .k-chart-shared-tooltip-marker {
  display: block;
  width: 15px;
  height: 3px;
  vertical-align: middle;
}

.k-chart-dragging {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.k-chart-donut-center {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  border: 4px solid transparent;
  box-sizing: border-box;
}

.k-pdf-export .k-chart .k-animation-container,
.k-pdf-export .k-sparkline .k-animation-container,
.k-pdf-export .k-stockchart .k-animation-container {
  display: none;
}

// grids

.k-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.k-grid.k-display-block {
  display: block;
}

.k-grid .k-grid-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}

.k-grid table {
  margin: 0;
  width: 100%;
  max-width: none;
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  outline: 0;
}

.k-grid tbody,
.k-grid tfoot,
.k-grid thead {
  text-align: left;
  border-color: inherit;
}

.k-grid tr {
  border-color: inherit;
  &:hover {
    @include background-opacity($color-grey, 0.2);
  }
}

.light-theme.k-grid tr {
  border-color: inherit;
  &:hover {
    @include background-opacity($color-grey, 0);
  }
}

.k-grid td,
.k-grid th {
  padding: 14px 12px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: 400;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-dark-grey;
}

.k-grid td:first-child,
.k-grid th:first-child {
  border-left-width: 0;
}

.k-grid td:focus,
.k-grid th:focus {
  outline: none;
}

.k-grid th {
  border-width: 0 0 1px 0;
  white-space: nowrap;
  font-weight: 500;
  padding: 18px 12px 14px 12px;
}

.k-grid td {
  border-width: 0 0 1px 0;
  vertical-align: middle;
  border-color: $color-dark-grey;
}

.k-grid a {
  color: inherit;
}

.k-grid a,
.k-grid a:hover {
  text-decoration: none;
}

.k-grid .k-grid-header-wrap,
.k-grid table {
  box-sizing: content-box;
}

.k-grid .k-group-col,
.k-grid .k-hierarchy-col {
  padding: 0;
  width: 32px;
}

.k-grid .k-grouping-row p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.k-grid .k-grouping-row td {
  overflow: visible;
}

.k-grid .k-grouping-row + tr td {
  border-top-width: 1px;
}

.k-grid .k-grouping-row + tr .k-group-cell,
.k-grid .k-grouping-row .k-group-cell {
  border-top-width: 0;
  text-overflow: none;
}

.k-grid .k-grouping-row .k-icon {
  margin-left: -4px;
  margin-right: 8px;
}

.k-grid .k-group-footer td {
  border-style: solid;
  border-width: 1px 0;
}

.k-grid .k-group-footer .k-group-cell + td {
  border-left-width: 1px;
}

.k-grid .k-hierarchy-cell {
  text-align: center;
  padding: 0;
  overflow: visible;
}

.k-grid .k-hierarchy-cell > .k-icon {
  padding: 8px 0;
  width: 100%;
  height: 100%;
  line-height: 1.4285714286;
  display: inline-block;
  outline: 0;
}

.k-grid .k-hierarchy-cell + td {
  border-left-width: 0;
}

.k-grid[dir="rtl"] tbody,
.k-grid[dir="rtl"] tfoot,
.k-grid[dir="rtl"] thead,
.k-rtl .k-grid tbody,
.k-rtl .k-grid tfoot,
.k-rtl .k-grid thead {
  text-align: right;
}

.k-grid[dir="rtl"] th,
.k-rtl .k-grid th {
  white-space: nowrap;
}

.k-grid[dir="rtl"] .k-grid-header-wrap,
.k-rtl .k-grid .k-grid-header-wrap {
  border-width: 0 0 0 1px;
}

.k-grid[dir="rtl"] .k-drag-clue .k-button.k-bare,
.k-grid[dir="rtl"] .k-group-indicator .k-button.k-bare,
.k-rtl .k-grid .k-drag-clue .k-button.k-bare,
.k-rtl .k-grid .k-group-indicator .k-button.k-bare {
  margin-left: -2px;
  margin-right: 8px;
}

.k-grid[dir="rtl"] .k-drag-clue .k-link .k-icon,
.k-grid[dir="rtl"] .k-group-indicator .k-link .k-icon,
.k-rtl .k-grid .k-drag-clue .k-link .k-icon,
.k-rtl .k-grid .k-group-indicator .k-link .k-icon {
  margin-left: 4px;
  margin-right: -2px;
}

.k-grid[dir="rtl"] .k-group-indicator + .k-group-indicator,
.k-rtl .k-grid .k-group-indicator + .k-group-indicator {
  margin-left: 0;
  margin-right: 8px;
}

.k-grid[dir="rtl"] .k-grid-content-locked,
.k-grid[dir="rtl"] .k-grid-footer-locked,
.k-grid[dir="rtl"] .k-grid-header-locked,
.k-rtl .k-grid .k-grid-content-locked,
.k-rtl .k-grid .k-grid-footer-locked,
.k-rtl .k-grid .k-grid-header-locked {
  border-left-width: 1px;
  border-right-width: 0;
}

.k-grid[dir="rtl"] td:first-child,
.k-grid[dir="rtl"] th:first-child,
.k-rtl .k-grid td:first-child,
.k-rtl .k-grid th:first-child {
  border-left-width: 1px;
}

.k-grid[dir="rtl"] td.k-hierarchy-cell,
.k-grid[dir="rtl"] td:last-child,
.k-grid[dir="rtl"] th:last-child,
.k-rtl .k-grid td.k-hierarchy-cell,
.k-rtl .k-grid td:last-child,
.k-rtl .k-grid th:last-child {
  border-left-width: 0;
}

.k-grid[dir="rtl"] .k-grid-footer,
.k-grid[dir="rtl"] .k-grid-header,
.k-rtl .k-grid .k-grid-footer,
.k-rtl .k-grid .k-grid-header {
  padding-left: 17px;
  padding-right: 0;
}

.k-grid[dir="rtl"] .k-grid-header .k-header:first-child,
.k-rtl .k-grid .k-grid-header .k-header:first-child {
  border-right-width: 0;
}

.k-grid[dir="rtl"] .k-filtercell-operator,
.k-rtl .k-grid .k-filtercell-operator {
  margin-left: 0;
  margin-right: 4px;
}

.k-grid[dir="rtl"] .k-dirty,
.k-rtl .k-grid .k-dirty {
  left: auto;
  right: 0;
}

.k-grid[dir="rtl"] .k-edit-cell > .k-block,
.k-grid[dir="rtl"] .k-edit-cell > .k-textbox,
.k-grid[dir="rtl"] .k-edit-cell > .k-widget,
.k-grid[dir="rtl"] .k-edit-cell > .text-box,
.k-grid[dir="rtl"] .k-grid-edit-row > td > .k-block,
.k-grid[dir="rtl"] .k-grid-edit-row > td > .k-textbox,
.k-grid[dir="rtl"] .k-grid-edit-row > td > .k-widget,
.k-grid[dir="rtl"] .k-grid-edit-row > td > .text-box,
.k-rtl .k-grid .k-edit-cell > .k-block,
.k-rtl .k-grid .k-edit-cell > .k-textbox,
.k-rtl .k-grid .k-edit-cell > .k-widget,
.k-rtl .k-grid .k-edit-cell > .text-box,
.k-rtl .k-grid .k-grid-edit-row > td > .k-block,
.k-rtl .k-grid .k-grid-edit-row > td > .k-textbox,
.k-rtl .k-grid .k-grid-edit-row > td > .k-widget,
.k-rtl .k-grid .k-grid-edit-row > td > .text-box {
  margin-right: -9px;
}

.k-grid .k-tooltip.k-tooltip-validation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: auto;
  padding: 4px 8px;
}

.k-grid .k-tooltip.k-tooltip-validation .k-callout {
  display: block;
}

.k-grid .k-animation-container-fixed .k-tooltip.k-tooltip-validation {
  position: static;
}

.k-grid .k-dirty-cell {
  position: relative;
}

.k-grid .k-dirty {
  border-width: 5px;
  left: 0;
  right: auto;
}

.k-grid-toolbar {
  padding: 8px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: inherit;
  text-overflow: clip;
  cursor: default;
  display: block;
}

.k-grid-toolbar .k-button {
  vertical-align: middle;
}

.k-grid-toolbar .k-button + .k-button {
  margin-left: 4px;
}

.k-grouping-header {
  display: block;
  padding: 8px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: inherit;
  line-height: calc(10px + 1.42857em);
}

.k-drag-clue,
.k-group-indicator {
  border-radius: 2px;
  margin: 0;
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  line-height: 1.4285714286;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  vertical-align: top;
}

.k-drag-clue .k-button.k-bare,
.k-drag-clue .k-link,
.k-group-indicator .k-button.k-bare,
.k-group-indicator .k-link {
  padding: 0;
  border-width: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.k-drag-clue .k-link .k-icon,
.k-group-indicator .k-link .k-icon {
  margin-left: -2px;
  margin-right: 4px;
}

.k-drag-clue .k-button.k-bare,
.k-group-indicator .k-button.k-bare {
  margin-left: 8px;
  margin-right: -2px;
  padding: 0;
  width: auto;
  height: auto;
  opacity: 0.5;
}

.k-drag-clue .k-button.k-bare:after,
.k-drag-clue .k-button.k-bare:before,
.k-group-indicator .k-button.k-bare:after,
.k-group-indicator .k-button.k-bare:before {
  display: none;
}

.k-drag-clue .k-button.k-bare:hover,
.k-group-indicator .k-button.k-bare:hover {
  opacity: 1;
}

.k-group-indicator + .k-group-indicator {
  margin-left: 8px;
}

.k-grouping-dropclue {
  width: 12px;
  height: calc(10px + 1.42857em);
  position: absolute;
}

.k-grouping-dropclue:after,
.k-grouping-dropclue:before {
  display: inline-block;
  content: "";
  position: absolute;
}

.k-grouping-dropclue:before {
  border-width: 6px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  top: 0;
}

.k-grouping-dropclue:after {
  width: 2px;
  height: calc(100% - 6px);
  top: 6px;
  left: 5px;
}

.k-grid-footer-wrap,
.k-grid-header-wrap {
  width: 100%;
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}

.k-grid-footer,
.k-grid-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 17px;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
}

.k-grid-footer table,
.k-grid-header table {
  table-layout: fixed;
}

div.k-grid-footer,
div.k-grid-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.k-ie div.k-grid-header {
  display: block;
}

.k-grid-header {
  border-bottom-width: 1px;
}

.k-grid-header table {
  margin-bottom: -1px;
}

.k-grid-header .k-header {
  position: relative;
  vertical-align: bottom;
}

.k-grid-header .k-header:first-child {
  border-left-width: 0;
}

.k-grid-header .k-header.k-first {
  border-left-width: 0; //1px;
}

.k-grid-header .k-header > .k-link {
  margin: -8px -12px;
  padding: 8px 12px;
  line-height: inherit;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-ff .k-grid-header .k-header > .k-link {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}

.k-grid-header .k-header.k-filterable > .k-link {
  margin-right: calc(10px + 1.42857em);
}

.k-grid-header .k-header > .k-link:focus {
  text-decoration: none;
}

.k-grid-header .k-header > .k-link > .k-icon {
  margin: 0 0.4ex;
  position: static;
  vertical-align: middle;
}

.k-grid-header .k-grid-filter,
.k-grid-header .k-header-column-menu {
  padding: 4px;
  width: calc(10px + 1.42857em);
  height: calc(10px + 1.42857em);
  box-sizing: border-box;
  outline: 0;
  line-height: 1.4285714286;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  float: right;
  position: relative;
  z-index: 1;
}

.k-grid-header .k-grid-filter {
  margin: -4px 0;
}

.k-grid-header .k-header-column-menu {
  margin: 0;
  margin-top: calc(0em + -5px);
  margin-bottom: calc(0em + -5px);
  margin-right: -12px;
}

.k-grid-header .k-header-column-menu + .k-link {
  margin-right: calc(1.42857em - 2px);
}

.k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm,
.k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm {
  vertical-align: text-top;
  margin-left: 7px;
}

.k-grid-header .k-sort-order {
  display: inline-block;
  font-size: 12px;
  height: 16px;
  margin-left: -4px;
  vertical-align: top;
  margin-top: 2px;
}

.k-grid-footer {
  border-width: 1px 0 0;
}

.k-grid-footer td {
  height: 1.4285714286em;
}

.k-filter-row {
  line-height: 1.4285714286;
}

.k-filter-row th {
  padding: 8px;
}

.k-filter-row .k-multiselect {
  height: auto;
}

.k-filtercell {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.k-filtercell .k-filtercell-wrapper,
.k-filtercell > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.k-filtercell .k-filtercell-wrapper > label,
.k-filtercell > span > label {
  vertical-align: middle;
}

.k-filtercell .k-filtercell-wrapper > .k-block,
.k-filtercell .k-filtercell-wrapper > .k-widget {
  width: 100%;
}

.k-filtercell > span .k-button {
  visibility: visible;
  pointer-events: all;
}

.k-filtercell > span .k-button,
.k-filtercell > span .k-dropdown-operator {
  margin-left: 4px;
}

.k-filtercell > span .k-numerictextbox {
  width: auto;
}

.k-filtercell .k-filtercell-operator > .k-button.k-clear-button-visible {
  visibility: visible;
  height: calc(10px + 1.42857em);
}

.k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible) {
  visibility: hidden;
  pointer-events: none;
}

.k-filtercell .k-filtercell-operator {
  margin-left: 4px;
}

.k-filtercell .k-block:not(.k-dropdown-operator),
.k-filtercell .k-filtercell-wrapper > .k-textbox,
.k-filtercell .k-widget:not(.k-dropdown-operator) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.k-filtercell .k-filtercell-wrapper > .k-textbox {
  width: 100%;
  min-width: 0;
}

.k-filtercell .k-autocomplete .k-input,
.k-filtercell .k-dropdown-wrap .k-input,
.k-filtercell .k-numeric-wrap .k-input,
.k-filtercell .k-picker-wrap .k-input,
.k-filtercell .k-selectbox .k-input,
.k-filtercell .k-textbox > input {
  padding-left: 0;
  padding-right: 0;
  text-indent: 8px;
}

.k-filtercell.k-filtercell-boolean .k-filtercell-wrapper {
  padding-right: calc(1.42857em + 14px);
  overflow: hidden;
}

.k-grid-content,
.k-grid-content-locked {
  border-color: inherit;
}

.k-grid-content-locked table,
.k-grid-content table {
  table-layout: fixed;
}

.k-grid-content-locked tr:last-child td,
.k-grid-content tr:last-child td {
  border-bottom-width: 0;
}

.k-grid-content {
  width: 100%;
  min-height: 0;
  overflow: auto;
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.k-grid-content .k-button {
  vertical-align: middle;
  margin: 0 0.16em;
}

.k-virtual-scrollable-wrap {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.k-grid-edit-row td {
  text-overflow: clip;
}

.k-grid-edit-row .k-block,
.k-grid-edit-row .k-button,
.k-grid-edit-row .k-input.k-textbox,
.k-grid-edit-row .k-textbox,
.k-grid-edit-row .k-widget {
  height: auto;
}

.k-grid-edit-row .k-edit-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.k-grid-edit-row .k-dirty-cell {
  overflow: visible;
}

.k-edit-cell > .k-block,
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-edit-cell > .text-box,
.k-grid-edit-row > td > .k-block,
.k-grid-edit-row > td > .k-textbox,
.k-grid-edit-row > td > .k-widget,
.k-grid-edit-row > td > .text-box {
  margin-left: -9px;
}

.k-edit-cell > .k-block,
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-grid-edit-row td > .k-block,
.k-grid-edit-row td > .k-textbox,
.k-grid-edit-row td > .k-widget {
  width: calc(100% + 18px);
}

.k-command-cell > .k-button,
.k-edit-cell > .k-block,
.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget,
.k-grid-edit-row td > .k-block,
.k-grid-edit-row td > .k-textbox,
.k-grid-edit-row td > .k-widget {
  margin-top: calc(0em + -5px);
  margin-bottom: calc(0em + -5px);
  vertical-align: middle;
}

.k-grid-header .k-resize-handle,
.k-grid > .k-resize-handle {
  height: 25px;
  cursor: col-resize;
  position: absolute;
  z-index: 2;
}

.k-grid-pager {
  padding: 8px;
  border-width: 1px 0 0;
  border-color: inherit;
}

.k-grid-virtual .k-grid-content .k-grid-table,
.k-grid-virtual .k-grid-content > table {
  position: absolute;
  z-index: 1;
}

.k-grid-virtual .k-grid-content > .k-height-container {
  position: relative;
}

.k-grid-add-row td {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.k-grid-lockedcolumns {
  white-space: nowrap;
}

.k-grid-content,
.k-grid-content-locked,
.k-pager-wrap {
  white-space: normal;
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  border-style: solid;
  border-width: 0 1px 0 0;
}

.k-grid-content,
.k-grid-footer-wrap,
.k-grid-header-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-block;
  vertical-align: top;
}

.k-grid-header-locked > table,
.k-grid-header-wrap > table {
  margin-bottom: -1px;
}

.k-header.k-drag-clue {
  overflow: hidden;
}

.k-grid-no-scrollbar .k-grid-header-wrap {
  border-width: 0;
}

.k-filter-menu .k-filter-menu-container,
.k-filter-menu > div:not(.k-animation-container) {
  padding: 0.5em 1em;
}

.k-filter-menu > div > div > .k-button {
  margin: 0.5em 1% 0;
  width: 48%;
  box-sizing: border-box;
  display: inline-block;
}

.k-filter-menu .k-block,
.k-filter-menu .k-radio-list,
.k-filter-menu .k-textbox,
.k-filter-menu .k-widget {
  margin: 0.5em 0;
  width: 100%;
  display: block;
}

.k-filter-menu .k-filter-and.k-block,
.k-filter-menu .k-widget.k-filter-and {
  width: 6em;
  margin: 1em 0;
}

.k-filter-menu .k-action-buttons {
  margin: 1em -1em -0.5em;
}

.k-filter-menu .k-action-buttons .k-button {
  margin: 0;
}

.k-multicheck-wrap {
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
  white-space: nowrap;
}

.k-multicheck-wrap .k-item + .k-item {
  margin-top: 0.5em;
}

.k-multicheck-wrap .k-label {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.k-multicheck-wrap .k-label input {
  margin: 0 0.25em;
}

.k-filter-selected-items {
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  margin: 1em 0 0.5em;
}

.k-autofitting {
  width: auto !important;
  table-layout: auto !important;
}

.k-autofitting td,
.k-autofitting th.k-header {
  white-space: nowrap !important;
}

.k-autofitting .k-detail-row {
  display: none !important;
}

.k-filter-row > th,
.k-grid-content-locked,
.k-grid-footer,
.k-grid-footer-locked,
.k-grid-footer-wrap,
.k-grid-header,
.k-grid-header-locked,
.k-grid-header-wrap,
.k-grid td,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-header {
  @include border-color-opacity($color-grey, 0.2);
}

.k-drag-clue,
.k-grid-add-row,
.k-grid-footer,
.k-grid-header,
.k-grid-toolbar,
.k-grouping-header {
  //color: $color-dark-grey;
  background-color: $color-light-grey;
}

.light-theme {
  .k-drag-clue,
  .k-grid-add-row,
  .k-grid-footer,
  .k-grid-header,
  .k-grid-toolbar,
  .k-grouping-header {
    //color: $color-dark-grey;
    background-color: transparent;
  }
}

.k-group-footer td,
.k-grouping-row td,
tbody .k-group-cell {
  color: $color-dark-grey;
  background-color: #ededed;
}

.k-grid-footer td,
.k-group-footer td,
.k-grouping-row td {
  font-weight: 700;
}

.k-grid {
  background-clip: padding-box;
}

.k-edge12 .k-grid,
.k-edge13 .k-grid,
.k-ie11 .k-grid {
  background-clip: border-box;
}

.k-grid tr.k-alt {
  //background-color: rgba(0, 0, 0, 0.04);
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected {
  color: inherit;
  background-color: rgba(255, 99, 88, 0.25);
}

.k-grid td.k-state-focused,
.k-grid th.k-state-focused {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-hierarchy-cell .k-icon {
  border-width: 0;
  color: #656565;
}

.k-grid .k-grouping-row {
  background-color: #ededed;
}

.k-grid .k-grouping-row .k-icon {
  color: #656565;
  text-decoration: none;
}

.k-grid .k-grouping-dropclue:before {
  border-color: #656565 transparent transparent;
}

.k-grid .k-grouping-dropclue:after {
  background-color: #656565;
}

.k-grid .k-tooltip.k-tooltip-validation {
  color: #fff;
  background-color: #ff6358;
}

.k-grid .k-tooltip.k-tooltip-validation .k-callout-n {
  border-bottom-color: #ff6358;
}

.k-grid .k-tooltip.k-tooltip-validation .k-callout-e {
  border-left-color: #ff6358;
}

.k-grid .k-tooltip.k-tooltip-validation .k-callout-s {
  border-top-color: #ff6358;
}

.k-grid .k-tooltip.k-tooltip-validation .k-callout-w {
  border-right-color: #ff6358;
}

.k-grid .k-dirty {
  border-color: currentColor transparent transparent currentColor;
}

.k-grid[dir="rtl"] .k-dirty,
.k-rtl .k-grid .k-dirty {
  border-color: currentColor currentColor transparent transparent;
}

col.k-sorted,
th.k-sorted {
  background-color: rgba(0, 0, 0, 0.02);
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: #ff6358;
}

.k-grid-header .k-grid-filter:hover {
  color: #656565;
  background-color: #ededed;
}

.k-grid-header .k-grid-filter:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.k-grid-header .k-grid-filter.k-state-active {
  color: #fff;
  background-color: #ff6358;
}

.k-grid .k-checkbox-label {
  cursor: default;
  outline: 0;
}

.k-grid-pdf-export-element {
  position: absolute;
  left: -10000px;
  top: -10000px;
}

.k-grid-pdf-export-element .k-filter-row {
  display: none;
}

.k-pager-wrap {
  padding: 8px;
  border-width: 1px;
  line-height: 1.4285714286;

  display: flex;
  align-items: flex-end;
  overflow: hidden;
  cursor: default;

  flex: 0 1 auto;
  flex-direction: column;
  min-height: 90px;
  @include media-breakpoint("gt-xs") {
    flex-direction: row;
    align-items: center;
    min-height: 50px;
  }
}

.k-pager-wrap .k-link,
.k-pager-wrap .k-state-selected {
  padding: 4px;
  width: calc(1.42857em + 2px);
  height: calc(1.42857em + 2px);
  line-height: calc(1.42857em + 2px);
  box-sizing: content-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 1;
}

.k-pager-wrap .k-link > .k-icon,
.k-pager-wrap .k-state-selected > .k-icon {
  vertical-align: middle;
}

.k-pager-wrap .k-link:hover,
.k-pager-wrap .k-state-selected {
  z-index: 2;
}

.k-pager-wrap .k-link:focus,
.k-pager-wrap .k-state-selected {
  text-decoration: none;
  outline: none;
}

.k-pager-wrap .k-link.k-state-disabled {
  color: inherit;
}

.k-pager-wrap .k-pager-numbers {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.k-pager-wrap .k-pager-numbers li {
  display: inline-block;
}

.k-pager-wrap .k-pager-numbers .k-current-page {
  display: none;
}

.k-pager-wrap .k-pager-numbers .k-current-page + li {
  margin-left: 0;
}

.k-pager-wrap .k-label {
  margin: 10px 10px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-basis: 100%;
  @include media-breakpoint("gt-xs") {
    flex-basis: 40%;
  }
}

.k-pager-wrap .k-pager-info.k-label {
  flex-basis: auto !important;
  margin: 0 1em;
}

.k-pager-wrap .k-pager-input .k-textbox {
  margin: 0 1ex;
  width: 3em;
}

.k-pager-wrap .k-pager-sizes .k-dropdown,
.k-pager-wrap .k-pager-sizes > select {
  width: 40px;
  margin: 0 8px;
  padding: 0 5px 0 0;
  border: none;
  // background: transparent url(assets/icons/gpm_menu.svg);
  background: transparent url(/assets/icons/gpm_menu.svg) no-repeat right center;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // text-indent: 1px;
  // text-overflow: '';
}

.k-pager-wrap .k-pager-refresh {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.k-pager-wrap .k-pager-info {
  // -webkit-box-flex: 1;
  // -ms-flex: 1;
  // flex: 1;
  // text-align: right;
  //-webkit-box-ordinal-group: 10;
  // -ms-flex-order: 2;
  // order: 2;
  // -webkit-box-pack: end;
  // -ms-flex-pack: end;
  // justify-content: flex-end;
}

.k-rtl .k-pager-wrap .k-i-arrow-e,
.k-rtl .k-pager-wrap .k-i-arrow-w,
.k-rtl .k-pager-wrap .k-i-seek-e,
.k-rtl .k-pager-wrap .k-i-seek-w,
[dir="rtl"] .k-pager-wrap .k-i-arrow-e,
[dir="rtl"] .k-pager-wrap .k-i-arrow-w,
[dir="rtl"] .k-pager-wrap .k-i-seek-e,
[dir="rtl"] .k-pager-wrap .k-i-seek-w {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.k-pager-wrap {
  color: $color-dark-grey;
  background-color: white;
  border-top: 1px solid $color-light-grey;
}

.k-pager-wrap .k-link:focus,
.k-pager-wrap .k-pager-nav:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-pager-wrap .k-link:hover,
.k-pager-wrap .k-pager-nav:hover {
  background-color: #ededed;
}

.k-pager-wrap .k-state-selected:focus {
  box-shadow: none;
}

.k-pager-wrap .k-state-selected:hover {
  color: #fff;
  background-color: #ff6358;
  cursor: default;
}

.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #ff6358;
}

.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link:link.k-state-selected {
  color: #ff6358;
}
