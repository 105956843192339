// define all style for the icon here
mat-icon {
    // font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.mat-icon {
    &[data-mat-icon-name="gpm_arrow_up"] {
        color: $color-dark-grey;
        width: 10px;
        height: 5px;
        line-height: 5px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_header_edit_underline"] {
        color: $color-dark-grey;
        width: 20px;
        height: 20px;
        line-height: 20px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_arrow_navigate_to"] {
        color: $color-ruby;
        width: 10px;
        height: 10px;
        line-height: 10px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_back_arrow"] {
        color: $color-ruby;
        width: 12px;
        height: 12px;
        line-height: 12px;
        min-width: initial;
        min-height: initial;
    }


    &[data-mat-icon-namespace="heroicons_outline"] {
        &[data-mat-icon-name="search"] {
            color: $color-dark-grey;
        }

        &[data-mat-icon-name="menu"] {
            color: $color-dark-grey;
        }
    }

    &[data-mat-icon-name="gpm_close"] {
        width: 20px;
        height: 20px;
        line-height: 20px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_warning"] {
        width: 20px;
        height: 17px;
        line-height: 17px;
        min-width: 0;
        min-height: 0;
        color: $color-red;
    }

    &[data-mat-icon-name="gpm_edit"] {
        width: 20px;
        height: 20px;
        line-height: 20px;
        min-width: 0;
        min-height: 0;
    }
    
    &[data-mat-icon-name="gpm_search"] {
        width: 17px;
        height: 17px;
        line-height: 16px;
        min-width: 0;
        min-height: 0;
    }

    &[data-mat-icon-name="gpm_close_no_border"] {
        color: #3B4456;
        width: 16px;
        height: 16px;
        line-height: 16px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_red_flag"] {
        width: 15px;
        height: 15px;
        line-height: 15px;
        min-width: initial;
        min-height: initial;
    }

    &[data-mat-icon-name="gpm_square_nav"] {
        width: 28px;
        height: 28px;
        line-height: 28px;
        min-width: initial;
        min-height: initial;
    }
}

.mat-icon-button {
    .mat-icon {
        &[data-mat-icon-name="gpm_previous_arrow"] {
            color: $color-ruby;
            width: 6px;
            height: 13px;
            line-height: 13px;
            min-width: initial;
            min-height: initial;
        }
        &[data-mat-icon-name="gpm_next_arrow"] {
            color: $color-ruby;
            width: 6px;
            height: 13px;
            line-height: 13px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_view"] {
            width: 20px;
            height: 26px;
            line-height: 16px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_setting"] {
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_providers"] {
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_sites"] {
            width: 16px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_calendar"] {
            color: $color-ruby;
            width: 20px;
            height: 22px;
            line-height: 22px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_plus"] {
            color: $color-dark-grey;
            width: 16px;
            height: 16px;
            line-height: 16px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_edit_underline"] {
            color: $color-dark-grey;
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_info"] {
            color: $color-dark-grey;
            width: 16px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_phone"] {
            color: $color-dark-grey;
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_email"] {
            color: $color-dark-grey;
            width: 20px;
            height: 16px;
            line-height: 16px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_currency"] {
            color: $color-dark-grey;
            width: 12px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_condition"] {
            color: $color-dark-grey;
            width: 14px;
            height: 18px;
            line-height: 18px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_header_appointment"] {
            color: $color-dark-grey;
            width: 19px;
            height: 18px;
            line-height: 18px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_threedot"] {
            color: $color-dark-grey;
            width: 22px;
            height: 4px;
            line-height: 4px;
            min-width: initial;
            min-height: initial;
        }
        &[data-mat-icon-name="gpm_slide_sheet_detail_info"] {
            color: $color-dark-grey;
            width: 18px;
            height: 19px;
            line-height: 19px;
            min-width: initial;
            min-height: initial;
        }
        &[data-mat-icon-name="gpm_expand_card"] {
            color: $color-ruby;
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_new"] {
            color: $color-dark-grey;
            width: 20px;
            height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_refresh"] {
            color: $color-dark-grey;
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

        &[data-mat-icon-name="gpm_info"] {
            color: $color-dark-grey;
            width: 20px;
            height: 20px;
            line-height: 20px;
            min-width: initial;
            min-height: initial;
        }

    }

    &.mat-primary:not(.mat-button-disabled) {
        .mat-icon {
            &[data-mat-icon-name="gpm_view"] {
                color: $color-dark-grey !important;
            }
            &[data-mat-icon-name="gpm_setting"] {
                color: $color-dark-grey !important;
            }
            &[data-mat-icon-name="gpm_header_plus"] {
                color: $color-dark-grey !important;
            }
        }
    }
}

img {
    &.gmp_app_icon_agg {
        display: inline-block;
        width: 38px;
        height: 38px;
    }

    &.gmp_app_icon_agg {
        display: inline-block;
        width: 38px;
        height: 38px;
    }

    &.gmp_app_icon_plus {
        display: inline-block;
        width: 38px;
        height: 38px;
    }
}
