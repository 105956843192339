.workspace {
    background-color: $color-light-grey;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 6.125rem 1.875rem 1.875rem 1.875rem;

    &--has-nav {
        height: calc(100% - 4.75rem);
    }

    @include media-breakpoint("lt-md") {
        padding: 5.5rem 1rem 1rem 1rem;
    }

    &-left-nav {
        .nav_item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: 74px;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;

            &.selected {
                background: $color-light-grey2;
                border-radius: 6px;
            }

            &--medical-notes,
            &--misc-billing,
            &--splinting,
            &--condition-detail,
            &--outcome-measures {
                svg {
                    width: 18px;
                }
            }

            .label {
                font-size: 10px;
                font-weight: 500;
                text-align: center;
            }
    
            .nav_icon {
                color: #3a4456 !important;
            }
        }
    }

    &-mobile-menu {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 4.8rem;
        cursor: pointer;
    
        .label {
            font-size: 0.625rem;
            font-weight: 500;
        }
    
        &-wrapper {
            border-left: 1px solid $color-grey;
            padding: 5px 0 0 10px;
        }
        
        &-btn {
            width: 24px;
            height: 24px;
            min-height: 24px;
            max-height: 24px;
            margin-left: 10px;
            top: 1px;
            z-index: 0;
            
            .mat-icon {
                background: $color-light-grey2;
                color: $color-dark-grey;
                border-radius: 50%;
                line-height: 18px;
            }
    
            &[aria-expanded="true"] {
                .mat-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &-header {
        &--desktop {
            > div {
                padding: 0 1.25rem 1rem 1.25rem;
            }
        
            @include media-breakpoint("xs") {
                display: none;
            }
        }

        &--mobile {
            display: none;

            > div {
                flex-direction: column;
                align-items: flex-start !important;
                line-height: 1.25;
            }
            
            @include media-breakpoint("xs") {
                display: block;
            }
        }

        .sub-title {
            position: relative;
            top: 2px;
        }
    }

    &-body {
        max-width: calc(100vw - 214px);

        @include media-breakpoint("lt-md") {
            max-width: calc(100vw - 134px);
        }
        
        @include media-breakpoint("xs") {
            max-width: 100%;
        }
    }

    &-nav {
        background-color: white;
        position: fixed;
        height: 4.75rem;
        color: red;
        bottom: 0;
        left: 0;
        z-index: 10;

        .nav-content {
            padding-left: 100px;
            padding-right: 1.25rem;

            @include media-breakpoint("sm") {
                padding-left: 1.25rem;
            }

            @include media-breakpoint("xs") {
                padding-left: 10px;
            }
        }

        &-icon-buttons {
            @include media-breakpoint("xs") {
                margin-left: -10px;

                .btn {
                    &--info {
                        display: none !important;
                    }
                }
            }
        }

        .nav-actions {
            margin: 0 -12px;
                
            @include media-breakpoint("xs") {
                margin: 0 -6px;
            }
    
            .action-list {
                padding: 0 12px;
                
                @include media-breakpoint("xs") {
                    padding: 0 6px;
                }
    
                @include media-breakpoint("lt-md") {
                    &--save-and-book,
                    &--save-and-view,
                    &--save {
                        display: none;
                    }
                }
    
                &--save-mobile {
                    display: none;
    
                    @include media-breakpoint("lt-md") {
                        display: block;
                    }
                }
            }
    
            .btn {
                &-wrap {
                    border-radius: 4px;
                    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
                }
    
                &--save {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-right: 1px solid #bf385a;
                }
    
                &--menu {
                    background: $color-ruby;
                    border-radius: 0 !important;
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
    
                    .mat-icon {
                        color: #fff;
                    }
                }
            }
        }
    }
}