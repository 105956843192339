/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Quill */
@import "~quill/dist/quill.snow.css";

/* Telerik */
@import "~@progress/kendo-theme-default/dist/all.css";
