// client header condition menu
.condition-menu {
    font-size: 16px;
    &.mat-menu-panel {
        max-width: 400px;
    }

    .mat-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .mat-menu-label {
        color: $color-ruby;
        font-weight: 500;
    }

    .mat-menu-item {
        height: 75px;
        white-space: normal;
        line-height: 22px;
        border-top: 1px solid #CFD7D7;

        .mat-icon {
            flex-shrink: 0;
        }
    }

    .menu-title {
        padding-right: 20px;
    }
}

//client header email menu
.email-menu{
    &.mat-menu-panel{
        max-width: 310px;
    }
}

// calendar mobile provider & site menu
.provider-menu, .site-menu{
    .title {
        font-weight: 700;
    }
}
.mat-menu-panel .provider-menu{
    min-width: 150px;
}


